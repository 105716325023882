import { ReactNode, forwardRef } from 'react';
import Button from 'src/components/Button';
import { twMerge } from 'tailwind-merge';

export type CalendarButtonProps = {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
};

const CalendarButton = forwardRef<HTMLButtonElement, CalendarButtonProps>(
  ({ onClick, className, children, disabled }, ref) => {
    return (
      <Button
        ref={ref}
        disabled={disabled}
        variant='secondary'
        size='small'
        onClick={onClick}
        className={twMerge('px-2 py-1', className)}
      >
        {children}
      </Button>
    );
  },
);

export default CalendarButton;
