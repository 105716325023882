import clsx from 'clsx';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import CalendarPageHeader from './components/CalendarPageHeader';

export type CalendarPageProps = {
  //
};

const CalendarPage: FC<CalendarPageProps> = () => {
  // const queryClient = useQueryClient();

  // const handleRefresh = useCallback(() => {
  //   queryClient.invalidateQueries(queryKeysFactory.orders._def);
  //   queryClient.invalidateQueries(queryKeysFactory.technicianOrder._def);
  //   queryClient.invalidateQueries(queryKeysFactory.allTechniciansDayOrders._def);
  //   queryClient.invalidateQueries(queryKeysFactory.technicianMonthOrders._def);
  // }, [queryClient]);

  return (
    <div className={clsx()}>
      <CalendarPageHeader className='mb-12' />
      <Outlet />
    </div>
  );
};

CalendarPage.displayName = 'CalendarPage';

export default CalendarPage;
