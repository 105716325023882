import clsx from 'clsx';
import { startOfDay, addMinutes, format, addDays } from 'date-fns';
import { FC, useRef, useState } from 'react';
import useMoveOrder from 'src/api/hooks/mutations/order/useMoveOrder';
import EventsGrid from 'src/components/EventsGrid';
import DraggableEvent from 'src/components/EventsGrid/components/DraggableEvent';
import HorizontalLines from 'src/components/EventsGrid/components/HorizontalLines';
import HoursBar from 'src/components/EventsGrid/components/HoursBar';
import VerticalLines from 'src/components/EventsGrid/components/VerticalLines';
import { MINUTES_PER_ROW, ROWS_PER_HOUR } from 'src/components/EventsGrid/constants';
import { eventToGridWeek, getGridCellDimensions, gridToTimeSpanWeek } from 'src/components/EventsGrid/helpers/grid';
import useHandleDropEvent from 'src/components/EventsGrid/hooks/useHandleDropEvent';
import WithLoader from 'src/components/utils/WithLoader';
import { snapToHalfHour } from 'src/helpers/datetime';
import AddServicePopup from 'src/pages/OrdersPage/AddServicePopup';
import { OrderSchema } from 'src/pages/OrdersPage/ServiceForm/schema';
import WeekDaysHeader from '../../components/WeekDaysHeader';
import useCalendarSelectedDateParam from 'src/pages/CalendarPage/hooks/useCalendarSelectedDateParam';
import useCalendarTechnicianIdsParam from 'src/pages/CalendarPage/hooks/useCalendarTechnicianIdsParam';

const START_HOUR = 0;
const MIN_ENABLED_HOUR = 0;
const END_HOUR = 24;

const WeekTab: FC = () => {
  const [technicianIds] = useCalendarTechnicianIdsParam();
  const [selectedDate] = useCalendarSelectedDateParam();
  const containerRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const events = [] as any[]; //useTechnicianGridEventsFromDay(technicianId, selectedDate);
  const { isLoading } = useMoveOrder();

  const handleDrop = useHandleDropEvent(events);

  const [isAddServicePopupOpen, setIsAddServicePopupOpen] = useState(false);
  const [addServicePopupDefaultValues, setAddServicePopupDefaultValues] = useState<Partial<OrderSchema>>({});

  const handleGridClick = (col: number, row: number): void => {
    const date = startOfDay(selectedDate);
    const start = addDays(addMinutes(date, snapToHalfHour(START_HOUR * 60 + MINUTES_PER_ROW * row)), col - 1);
    const end = addMinutes(start, 60);

    setAddServicePopupDefaultValues({
      technicianIds: technicianIds.map((i) => ({ technicianId: i })),
      _date: start,
      _start: format(start, 'HH:mm'),
      _end: format(end, 'HH:mm'),
    });
    setIsAddServicePopupOpen(true);
  };

  return (
    <>
      <WithLoader isLoading={isLoading}>
        <div ref={containerRef} className={clsx('flex flex-col border border-neutral-300 overflow-y-scroll rounded')}>
          <WeekDaysHeader className='' />
          <EventsGrid
            olClassName=''
            scrollContainerRef={containerRef}
            events={events}
            eventToGrid={eventToGridWeek}
            gridToTimeSpan={gridToTimeSpanWeek}
            getGridCellDimensions={getGridCellDimensions}
            cols={7}
            rows={ROWS_PER_HOUR * (END_HOUR - START_HOUR)}
            startHour={START_HOUR}
            endHour={END_HOUR}
            disabledColumns={[6, 7]}
            autoScrollToHour={MIN_ENABLED_HOUR}
            scrollTopOffest={64}
            minEnabledMinute={60 * MIN_ENABLED_HOUR}
            onDrop={handleDrop}
            onGridClick={handleGridClick}
            renderEvent={(event, props) => <DraggableEvent key={event.id} {...event} {...props} />}
            renderHoursBar={() => <HoursBar />}
            renderHorizontalLines={() => <HorizontalLines />}
            renderVerticalLines={() => <VerticalLines />}
          />
        </div>
      </WithLoader>
      <AddServicePopup
        defaultValues={addServicePopupDefaultValues}
        open={isAddServicePopupOpen}
        onClose={() => setIsAddServicePopupOpen(false)}
      />
    </>
  );
};

WeekTab.displayName = 'WeekTab';

export default WeekTab;
