import { FC, useState } from 'react';
import useCalendarTechnicianIdsParam from '../../hooks/useCalendarTechnicianIdsParam';
import { useFloating, offset, useClick, useDismiss, useInteractions } from '@floating-ui/react';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import PageCard from 'src/components/PageCard';
import { twMerge } from 'tailwind-merge';
import CalendarButton from '../CalendarButton';
import useAllTechnicians from 'src/api/hooks/queries/useAllTechnicians';
import Checkbox from 'src/components/fields/Checkbox';
import { UserResponse } from 'src/api';

export type CalendarTechniciansSelectProps = {
  className?: string;
  technicians: UserResponse[];
};

const CalendarTechniciansSelectContainer: FC<Omit<CalendarTechniciansSelectProps, 'technicians'>> = ({ className }) => {
  const { data: technicians, isLoading } = useAllTechnicians();

  if (isLoading) {
    return (
      <CalendarButton
        disabled
        className={twMerge('w-max min-w-10 cursor-pointer flex items-center gap-1 text-gray-700', className)}
      >
        <AdjustmentsHorizontalIcon className='w-6 h-6' />
      </CalendarButton>
    );
  }

  return <CalendarTechniciansSelect className={className} technicians={technicians ?? []} />;
};

const CalendarTechniciansSelect: FC<CalendarTechniciansSelectProps> = ({ technicians, className }) => {
  const [technicianIds, setTechnicianIds] = useCalendarTechnicianIdsParam({
    defaultValue: technicians.map((i) => i.id),
  });

  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    middleware: [offset(10)],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, { ancestorScroll: true });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return (
    <>
      <CalendarButton
        className={twMerge('w-max min-w-10 cursor-pointer flex items-center gap-1 text-gray-700', className)}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <AdjustmentsHorizontalIcon className='w-6 h-6' />
      </CalendarButton>
      {isOpen && (
        <div ref={refs.setFloating} style={floatingStyles} className='z-[600]' {...getFloatingProps()}>
          <PageCard>
            {technicians?.map((technician) => (
              <Checkbox
                name={`technician[${technician.id}]`}
                key={technician.id}
                label={technician.profile?.name}
                value={technicianIds.includes(technician.id)}
                onChange={(nextValue) => {
                  if (nextValue) {
                    setTechnicianIds((prev) => [...(prev ?? []), technician.id]);
                    return;
                  }
                  setTechnicianIds((prev) => (prev ?? []).filter((i) => i !== technician.id));
                }}
              />
            ))}
          </PageCard>
        </div>
      )}
    </>
  );
};

export default CalendarTechniciansSelectContainer;
