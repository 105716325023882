import { InboxIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

export type UnassignedTechniciansBarItemProps = {
  onClick?: () => void;
  className?: string;
};

const UnassignedTechniciansBarItem: FC<UnassignedTechniciansBarItemProps> = ({ className, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={twMerge(
        'bg-white h-[7.5rem] w-40 p-3 flex flex-col justify-end border-b-2 border-neutral-200',
        !onClick && 'cursor-pointer',
        className,
      )}
    >
      <InboxIcon className='w-6 h-6' />
      <span className='text-lg font-semibold'>
        <FormattedMessage id='app.orders.unassigned' />
      </span>
    </div>
  );
};

export default UnassignedTechniciansBarItem;
