import { FC } from 'react';
import { match, P } from 'ts-pattern';
import useCalendarMode from '../../hooks/useCalendarMode';
import { addDays, format, startOfWeek } from 'date-fns';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';
import useCalendarSelectedDateParam from '../../hooks/useCalendarSelectedDateParam';
import { twMerge } from 'tailwind-merge';

export type CalendarModeDatePreviewProps = {
  className?: string;
};

const CalendarModeDatePreview: FC<CalendarModeDatePreviewProps> = ({ className }) => {
  const [date] = useCalendarSelectedDateParam();
  const { data: locale } = useUserFnsLocale();
  const mode = useCalendarMode();

  const weekStart = startOfWeek(date);

  return match(mode)
    .with(P.union('day', 'timeline'), () => (
      <span className={twMerge('capitalize', className)}>{format(date, 'EEEE, dd.MM.yyyy', { locale })}</span>
    ))
    .with('week', () => (
      <span className={className}>
        <span>{format(weekStart, 'dd.M.yyyy')}</span> - <span>{format(addDays(weekStart, 6), 'dd.M.yyyy')}</span>
      </span>
    ))
    .with('month', () => <span className={className}>{format(weekStart, 'MMMM')}</span>)
    .with(null, () => null)
    .exhaustive();
};

export default CalendarModeDatePreview;
