import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import PageTitle from 'src/components/PageTitle';
import { twMerge } from 'tailwind-merge';
import useCalendarSelectedDateParam from '../../hooks/useCalendarSelectedDateParam';
import CalendarButton from '../CalendarButton';
import { addDays, addMonths, addWeeks, subDays, subMonths, subWeeks } from 'date-fns';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { NavLink } from 'react-router-dom';
import CalendarModeDatePreview from '../CalendarModeDatePreview/CalendarModeDatePreview';
import CalendarDateSelect from '../CalendarDateSelect/CalendarDateSelect';
import CalendarTechniciansSelect from '../CalendarTechniciansSelect';
import useCalendarMode from '../../hooks/useCalendarMode';
import { match, P } from 'ts-pattern';
import CalendarSingleTechnicianSelect from '../CalendarSingleTechnicianSelect';
import useEvent from 'src/hooks/useEvent';

export type CalendarPageHeaderProps = {
  className?: string;
};

const CalendarPageHeader: FC<CalendarPageHeaderProps> = ({ className }) => {
  const mode = useCalendarMode();
  const [, setDate] = useCalendarSelectedDateParam();

  const handleNext = useEvent(() =>
    match(mode)
      .with('month', () => setDate((prev) => addMonths(prev ?? new Date(), 1)))
      .with('week', () => setDate((prev) => addWeeks(prev ?? new Date(), 1)))
      .with(P.union('timeline', 'day'), () => setDate((prev) => addDays(prev ?? new Date(), 1)))
      .with(null, () => undefined)
      .exhaustive(),
  );

  const handlePrev = useEvent(() =>
    match(mode)
      .with('month', () => setDate((prev) => subMonths(prev ?? new Date(), 1)))
      .with('week', () => setDate((prev) => subWeeks(prev ?? new Date(), 1)))
      .with(P.union('timeline', 'day'), () => setDate((prev) => subDays(prev ?? new Date(), 1)))
      .with(null, () => undefined)
      .exhaustive(),
  );

  return (
    <div className={twMerge('flex gap-y-4 gap-x-6 flex-col lg:flex-row', className)}>
      <PageTitle>
        <FormattedMessage id='app.calendar_page.title' />
      </PageTitle>

      <div className='flex gap-y-4 flex-col lg:flex-row w-full justify-between'>
        <div className='flex gap-x-6 items-center'>
          <CalendarButton className='px-4' onClick={() => setDate(new Date())}>
            <FormattedMessage id='app.common.now' />
          </CalendarButton>

          <div className='flex'>
            <CalendarButton className='rounded-r-none' onClick={handlePrev}>
              <ChevronLeftIcon className='w-6 h-6' />
            </CalendarButton>
            <CalendarButton className='rounded-l-none' onClick={handleNext}>
              <ChevronRightIcon className='w-6 h-6' />
            </CalendarButton>
          </div>

          <CalendarModeDatePreview />
        </div>

        <div className='flex gap-x-6 justify-end'>
          <div className='flex'>
            <NavLink className='h-full flex' to='/calendar/day'>
              {({ isActive }) => (
                <CalendarButton className='rounded-r-none' disabled={isActive}>
                  <FormattedMessage id='app.technician_account.calendar.tab.day' />
                </CalendarButton>
              )}
            </NavLink>
            <NavLink className='h-full flex' to='/calendar/week'>
              {({ isActive }) => (
                <CalendarButton className='rounded-none' disabled={isActive}>
                  <FormattedMessage id='app.technician_account.calendar.tab.week' />
                </CalendarButton>
              )}
            </NavLink>
            <NavLink className='h-full flex' to='/calendar/month'>
              {({ isActive }) => (
                <CalendarButton disabled={isActive} className='rounded-none'>
                  <FormattedMessage id='app.technician_account.calendar.tab.month' />
                </CalendarButton>
              )}
            </NavLink>
            <NavLink className='h-full flex' to='/calendar/timeline'>
              {({ isActive }) => (
                <CalendarButton className='rounded-l-none' disabled={isActive}>
                  <FormattedMessage id='app.calendar.timeline' />
                </CalendarButton>
              )}
            </NavLink>
          </div>
          {match(mode)
            .with(P.union('timeline', 'day', 'week'), () => <CalendarDateSelect />)
            .with(P.union('month', null), () => null)
            .exhaustive()}

          {match(mode)
            .with('timeline', () => <CalendarTechniciansSelect />)
            .with(P.union('day', 'week', 'month'), () => <CalendarSingleTechnicianSelect />)
            .with(null, () => null)
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default CalendarPageHeader;
